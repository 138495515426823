import { usePostHog } from "posthog-js/react";
import { SubscriptionPlan } from "./SubscriptionPlan";
import {
  MONTHLY_PRICE,
  SALE_PERCENTAGE,
  ANNUAL_MONTHLY_PRICE,
  SALE_MONTHLY_PRICE,
  SALE_ANNUAL_MONTHLY_PRICE,
  MONTHLY_PRICE_DECIMAL,
  ANNUAL_MONTHLY_PRICE_DECIMAL,
} from "./../../../pages/premium/paymentsConfig";
import { useState } from "react";
import { usePaymentStore } from "../../../stores/payment";

type Props = {
  onMonthlyPress: VoidFunction;
  onAnnualPress: VoidFunction;
};

export const PremiumProducts = ({ onMonthlyPress, onAnnualPress }: Props) => {
  const postHog = usePostHog();
  const featureFlag = postHog?.getFeatureFlag("show-sale");
  const showDecimalPrices =
    postHog?.getFeatureFlag("show-decimal-prices") === "show";
  const { selectedPlan, setSelectedPlan } = usePaymentStore(); // Defaulted to annual in the state

  const monthlyPrice =
    featureFlag === "show"
      ? SALE_MONTHLY_PRICE
      : showDecimalPrices
      ? MONTHLY_PRICE_DECIMAL
      : MONTHLY_PRICE;

  const monthlyAnnualPrice =
    featureFlag === "show"
      ? SALE_ANNUAL_MONTHLY_PRICE
      : showDecimalPrices
        ? ANNUAL_MONTHLY_PRICE_DECIMAL
        : ANNUAL_MONTHLY_PRICE;

  const salePercentage = featureFlag === "show" ? SALE_PERCENTAGE : 0;

  const handlePlanClick = (plan: "annual" | "monthly") => {

    setSelectedPlan(plan);
    // Trigger the appropriate payment function immediately
    if (plan === "annual") {
      onAnnualPress();
    } else {
      onMonthlyPress();
    }
  };

  const handleGetStarted = () => {
    if (selectedPlan === 'annual') {
      onAnnualPress();
    } else {
      onMonthlyPress();
    }
  };

  return (
    <div className="flex flex-col p-4">
      <div className="flex space-x-2">
        <div 
          className="flex-1 cursor-pointer" 
          onClick={() => handlePlanClick('annual')}
        >
          <SubscriptionPlan
            popular
            title="Annual"
            price={monthlyAnnualPrice}
            fullPrice={monthlyPrice}  // Pass the monthly price as the full price
            salePercentage={salePercentage}
            additionalDescription="Save 64%"
            isSelected={selectedPlan === 'annual'}
          />
        </div>
        <div 
          className="flex-1 cursor-pointer" 
          onClick={() => handlePlanClick('monthly')}
        >
          <SubscriptionPlan
            title="Monthly"
            price={monthlyPrice}
            salePercentage={salePercentage}
            isSelected={selectedPlan === 'monthly'}
          />
        </div>
      </div>
      {/* <p className="text-xs text-white font-ultralight text-center mt-2">
        7-day money back guarantee
      </p> */}
      <button
        className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
        onClick={handleGetStarted}
      >
        Get Started
      </button>
    </div>
  );
};
