export const MONTHLY_PRICE = 14
export const MONTHLY_PRICE_DECIMAL = 13.99
export const ANNUAL_MONTHLY_PRICE = 5
export const ANNUAL_MONTHLY_PRICE_DECIMAL = 4.99
export const ANNUAL_PRICE = 60
export const ANNUAL_PRICE_DECIMAL = 59.99

export const SALE_MONTHLY_PRICE = 23
export const SALE_ANNUAL_MONTHLY_PRICE = 8
export const SALE_ANNUAL_PRICE = 100

export const AIPHOTOS_SUBSCRIBED = 15
export const AIPHOTOS_SUBSCRIBED_DECIMAL = 14.99
export const AIPHOTOS_UNSUBSCRIBED = 29
export const AIPHOTOS_UNSUBSCRIBED_DECIMAL = 28.99

export const SALE_PERCENTAGE = 40

export const AIPHOTOS_BASIC_PRICE = 29
export const AIPHOTOS_BASIC_PRICE_SUBSCRIBED = 15
export const AIPHOTOS_PLUS_PRICE = 49
export const AIPHOTOS_PLUS_PRICE_SUBSCRIBED = 25
