import { useEffect, useRef, useState } from "react"
import { SALE_PERCENTAGE } from "../../../pages/premium/paymentsConfig"

const SALE_ENDS = import.meta.env.VITE_SALE_ENDS

export const SaleBanner = () => {
  const [timeLeft, setTimeLeft] = useState("")
  const salePercentage = useRef(SALE_PERCENTAGE).current
  const saleEnds = useRef(SALE_ENDS).current

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000); // Get current time in seconds
      const timeRemaining = saleEnds - now;

      if (timeRemaining > 0) {
        const days = Math.floor(timeRemaining / 86400);
        const hours = Math.floor((timeRemaining % 86400) / 3600);
        const minutes = Math.floor((timeRemaining % 3600) / 60);
        const seconds = timeRemaining % 60;

        let displayTime = "";

        if (days > 0) {
          displayTime += `${days} Day${days > 1 ? 's' : ''} `;
        }

        displayTime += `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        setTimeLeft(displayTime);
      } else {
        setTimeLeft("");
        clearInterval(countdownInterval); // Stop the timer when the countdown reaches zero
      }
    }, 1000);

    return () => clearInterval(countdownInterval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="mt-2 p-4 bg-brand-secondary flex items-center justify-between ">
      <p className="text-xs text-white">{`SUMMER SALE 🌞 ${salePercentage}% OFF`}</p>
      {saleEnds && timeLeft ? (
        <p className="text-xs">
          <span className="text-[#999999] mr-1">ENDS IN </span>
          <span className="text-white font-mono inline-block text-center">
            {timeLeft}
          </span>
        </p>
      ) : null}
    </div>
  )
}