import { useProfileStore } from "../../stores/profile";
import { AIPhotosModal } from "../ai-photos/AIPhotosModal";
import { FullProfileReview } from './FullProfileReview'
import { PartialProfileReview } from './PartialProfileReview'
import { FamewallReviews } from "../../pages/FamewallReviews";
import { logEvent, useLogEvent } from "../../analytics";
import { usePaymentStore } from "../../stores/payment";
import { usePostHog } from 'posthog-js/react'
import { OutlineButton } from '../OutlineButton'
import { useAuthStore } from "../../stores/auth";

type Props = {
  onReviewAnotherProfileClick?: VoidFunction
}

export const ProfileReviewResult = ({ onReviewAnotherProfileClick }: Props) => {
  const postHog = usePostHog()
  const { reviewedProfile, hasPaidForProfileReview } = useProfileStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore();
  const { isSubscribed } = useAuthStore()

  useLogEvent('results', 'profile_review');

  const onUnlockFullReviewClick = () => {
    logEvent('purchase_unlock', 'profile_review', {}, 'payment');
    setProduct("profile_review");
    setOpenPaymentModal(true);
    postHog?.capture('review_payment_unlock')
  }

  return (
    <>
      <div className="mx-auto max-w-xl">
        <div className="flex flex-col flex-1">
          <div className="mt-8">
            <div className="border-2 border-black rounded-2xl shadow-lg bg-white">
              <div className="flex flex-1 rounded-t-2xl bg-brand-alt-light bg-opacity-100">
                <div className="py-3 px-2 items-center flex-1">
                  <p className="font-semibold ml-2 text-sm">Current Rating</p>
                  <p className="font-bold ml-2 text-2xl">{`${reviewedProfile?.currentRating ?? 0}/10`}</p>
                </div>
                <div className="py-3 px-2 mr-2 items-center">
                  <p className="font-semibold ml-2 text-sm">Rating after improvement</p>
                  <p className="font-bold ml-2 text-2xl text-brand-primary">{`${reviewedProfile?.possibleRating ?? 0}/10`}</p>
                </div>
              </div>
              <div className="p-4 rounded-b-2xl">
                {hasPaidForProfileReview || isSubscribed ?
                  <FullProfileReview review={reviewedProfile} /> :
                  <PartialProfileReview summary={reviewedProfile?.summary ?? ""} onUnlockFullReviewClick={onUnlockFullReviewClick} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};