import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import { useAuthStore } from "../../../stores/auth";
import AIPhotosPaymentForm from "../AIPhotosPaymentForm";
import { AIPhotosPlan } from "../../../constants/payments";
import { AIPHOTOS_BASIC_PRICE, AIPHOTOS_BASIC_PRICE_SUBSCRIBED, AIPHOTOS_PLUS_PRICE, AIPHOTOS_PLUS_PRICE_SUBSCRIBED } from "../../../pages/premium/paymentsConfig";

interface Props {
  returnUrl: string;
  redirectHandler?: () => void;
  selectedPlan: AIPhotosPlan;
}

export const AIPhotosPaywall = ({
  returnUrl,
  redirectHandler,
  selectedPlan
}: Props) => {
  const { isSubscribed } = useAuthStore();

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptionsMode = {
    mode: 'payment',
    amount: selectedPlan === AIPhotosPlan.Plus
      ? (isSubscribed ? AIPHOTOS_PLUS_PRICE_SUBSCRIBED * 100 : AIPHOTOS_PLUS_PRICE * 100)
      : (isSubscribed ? AIPHOTOS_BASIC_PRICE_SUBSCRIBED * 100 : AIPHOTOS_BASIC_PRICE * 100),
    currency: 'usd',
    appearance,
  };

  return (
    <div className="bg-white rounded-lg border border-black p-4 overflow-y-scroll mt-10">
      <div className="flex mb-4">
        <div className="w-1/2 font-semibold">
          {selectedPlan === AIPhotosPlan.Plus ? 'AI Enhanced Photos - Plus' : 'AI Enhanced Photos'}
        </div>
        <div className="w-1/2 flex justify-end items-center">
          {isSubscribed ? (
            <span>${selectedPlan === AIPhotosPlan.Plus ? AIPHOTOS_PLUS_PRICE_SUBSCRIBED : AIPHOTOS_BASIC_PRICE_SUBSCRIBED}</span>
          ) : (
            <span>${selectedPlan === AIPhotosPlan.Plus ? AIPHOTOS_PLUS_PRICE : AIPHOTOS_BASIC_PRICE}</span>
          )}
          &nbsp;once off
        </div>
      </div>
      <div className="mt-1">
        <Elements options={options} stripe={stripePromise}>
          <AIPhotosPaymentForm
            returnUrl={returnUrl}
            redirectHandler={redirectHandler}
            selectedPlan={selectedPlan}
          />
        </Elements>
      </div>
    </div>
  );
};
