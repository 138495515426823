import Good1 from "../../assets/images/ai-photos/good-1.webp";
import Good2 from "../../assets/images/ai-photos/good-2.webp";
import Good3 from "../../assets/images/ai-photos/good-3.webp";
import Bad1 from "../../assets/images/ai-photos/bad-1.webp";
import Bad2 from "../../assets/images/ai-photos/bad-2.webp";
import Bad3 from "../../assets/images/ai-photos/bad-3.webp";
import { useAIPhotosUpload } from "./useAIPhotosUpload";

type Props = {
  onContinue: VoidFunction;
}

export const UploadPhotosStep = ({
  onContinue,
}: Props) => {
  const { getRootProps, getInputProps, uploading } = useAIPhotosUpload(onContinue, true);
  return (
    <div className="flex flex-col">
      <h1 className="text-2xl font-bold mb-3 text-center">Upload your photos</h1>
      <p className="text-sm text-gray-600 mb-3 text-center">
        Upload 5 - 20 photos of yourself. More photos generally leads to better results.
      </p>
      <div className="mb-6 p-4 border border-black rounded-xl bg-white">
        <h4 className="font-semibold text-lg mb-2">Good Examples</h4>
        <ul className="list-disc pl-4 mb-4">
          <li>Face clearly visible</li>
          <li>At least 1-2 full body photos</li>
          <li>Variety of expressions</li>
        </ul>
        {/* <p className="text-sm mb-4">
          Don’t rush it, take some photos if you don’t have them 😎
        </p> */}
        <div className="flex space-x-2">
          <div className="w-1/3 h-30">
            <img
              src={Good1}
              alt="Good Example 1"
              className="object-cover rounded-lg"
            />
          </div>
          <div className="w-1/3 h-30">
            <img
              src={Good2}
              alt="Good Example 2"
              className="object-cover rounded-lg"
            />
          </div>
          <div className="w-1/3 h-30">
            <img
              src={Good3}
              alt="Good Example 3"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="p-4 border border-black rounded-xl bg-white">
        <h4 className="font-semibold text-lg mb-2">Bad Examples</h4>
        <ul className="list-disc pl-4 mb-4">
          <li>Other people</li>
          <li>Angled selfies</li>
          <li>Overly zoomed in photos</li>
          <li>Duplicates</li>
          <li>Overly filtered photos</li>
          <li>Black and white</li>
          <li>Cropped face </li>
          <li>Sunglasses</li>
        </ul>
        <div className="flex space-x-2">
          <div className="w-1/3 h-30">
            <img
              src={Bad1}
              alt="Bad Example 1"
              className="object-cover rounded-lg"
            />
          </div>
          <div className="w-1/3 h-30">
            <img
              src={Bad2}
              alt="Bad Example 2"
              className="object-cover rounded-lg"
            />
          </div>
          <div className="w-1/3 h-30">
            <img
              src={Bad3}
              alt="Bad Example 3"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </div>
      
      <div
        {...getRootProps()}
        className={`w-full h-12 mt-6 mb-4 flex items-center justify-center bg-brand-primary text-white py-3 rounded-md font-semibold text-center ${uploading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      >
        <input {...getInputProps()} />
        {uploading ? (
          <div className="flex items-center">
            <span>Uploading...</span>
            <svg
              className="animate-spin -ml-1 ml-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          'Upload your photos'
        )}
      </div>
      <p className="text-xs text-gray-600 mt-2 text-center mb-4">
        We don't share or save your photos. Photos are deleted 7 days after your images are generated.
      </p>
    </div >
  );
};