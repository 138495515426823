import { useEffect, useRef, useState } from "react"
import { premiumData, PremiumDataItem } from "../../../pages/premium/data";
import { PremiumProductStep } from "./PremiumProductStep";
import { ProductType } from "../../../models/payment";

const reorderData = (
  data: PremiumDataItem[],
  firstKey: string | undefined = undefined
) => {
  if (!firstKey) return data;
  const key = firstKey === "photo_review" ? "profile_review" : firstKey;
  const index = data.findIndex((item) => item.key === key);
  if (index === -1) return data; // Return the data as is if the key isn't found
  return [data[index], ...data.slice(0, index), ...data.slice(index + 1)];
};

type Props = {
  initialKey?: ProductType
  transparentBackground?: boolean;

}

export const PremiumProductsCarousel = ({
  initialKey = undefined,
  transparentBackground,
}: Props) => {
  const [step, setStep] = useState(0);
  const headingRefs = useRef<(HTMLDivElement | null)[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [data, setData] = useState(() => reorderData(premiumData, initialKey));

  useEffect(() => {
    setData(reorderData(premiumData, initialKey));
  }, [initialKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setStep((prevStep) => (prevStep + 1) % premiumData.length);
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [isDragging]);

  const handleHeadingClick = (index: number) => {
    setStep(index);
  };

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault(); // Prevent default drag behavior
    setIsDragging(true);
    setStartX('touches' in e ? e.touches[0].pageX : e.pageX);
    setDragDistance(0);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    if (Math.abs(dragDistance) > 20) { // Reduced threshold for more sensitivity
      if (dragDistance > 0 && step > 0) {
        setStep(step - 1);
      } else if (dragDistance < 0 && step < premiumData.length - 1) {
        setStep(step + 1);
      }
    }
    setDragDistance(0);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    const currentX = 'touches' in e ? e.touches[0].pageX : e.pageX;
    const newDragDistance = currentX - startX;
    setDragDistance(newDragDistance);
  };

  return (
    <div className={transparentBackground ? "bg-transparent" : "bg-main"}>
      <div className="relative">
        <div className="flex flex-row text-black justify-between mb-4 py-2 pt-4">
          {data.map(({ heading }, index) => (
            <div
              key={index}
              className={`flex-grow text-center cursor-pointer transition-colors duration-300 select-none ${
                index === step ? "text-black font-semibold" : "text-gray-500"
              }`}
              ref={(el) => (headingRefs.current[index] = el)}
              onClick={() => handleHeadingClick(index)}
            >
              {heading}
            </div>
          ))}
        </div>
        <div
          className="absolute bottom-0 left-0 h-1 bg-black transition-all duration-500 ease-in-out"
          style={{
            width: headingRefs.current[step]?.offsetWidth || 0,
            transform: `translateX(${
              headingRefs.current[step]?.offsetLeft || 0
            }px)`,
          }}
        />
      </div>
      <div
        className="flex flex-col flex-1 relative w-full overflow-hidden cursor-grab active:cursor-grabbing"
        ref={carouselRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        onMouseMove={handleDragMove}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
        onTouchMove={handleDragMove}
      >
        <div
          className="w-full inline-flex transition-transform duration-500 ease-in-out select-none"
          style={{
            transform: `translateX(calc(-${step * 100}% + ${isDragging ? dragDistance : 0}px))`,
          }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0"
              onDragStart={(e) => e.preventDefault()}
            >
              <PremiumProductStep
                illustration={item.image}
                title={item.title}
                description={item.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};