import { AIPhotosWizard } from './AIPhotosWizard'
import { AIPhotosWizardStep, useAIPhotosStore } from '../../stores/aiphotos'
import { Loading } from '../../components/Loading';
import { AIPhotosResults } from './AIPhotosResults';
import { AIPhotosLanding } from './AIPhotosLanding';
import { useEffect, useState } from 'react';
import { UnlockAIPhotosModal } from '../../components/modals/UnlockAIPhotosModal';
import toast from 'react-hot-toast';
import { AIPhotosProcessingStatus, useAIPhotosTask } from './useAIPhotosTask';
import { AIPhotosPlan } from '../../constants/payments';

export type AIPhotoBatch = {
  email: string
  photos: string[]
  tuneId: number
  status: string
  createdAt: string
  outputUrls?: string[]
}

export const AIPhotos = () => {
  const [openUnlockAIPhotosModal, setOpenUnlockAIPhotosModal] = useState(false)
  const [showLandingPage, setShowLandingPage] = useState(true)
  const [loadingTitles, setLoadingTitles] = useState<string[]>([])
  const [selectedPlan, setSelectedPlan] = useState<AIPhotosPlan>(AIPhotosPlan.Basic);

  const {
    age,
    gender,
    ethnicity,
    eyeColor,
    aiPhotosWizardComplete,
    setAIPhotosWizardComplete,
    generatingAIPhotos,
    setGeneratingAIPhotos,
    clearFilesToUpload,
    setAIPhotosWizardStep,
    aiPhotosEmail
  } = useAIPhotosStore();

  const { generateAIPhotos, aiPhotosProcessingStatus } = useAIPhotosTask(selectedPlan)
  const { filesToUpload } = useAIPhotosStore()

  const hasAllRequiredFields = age !== '' && gender !== '' && ethnicity !== '' && eyeColor !== '' && aiPhotosEmail !== ''

  useEffect(() => {
    if (hasAllRequiredFields) {
      setAIPhotosWizardStep(AIPhotosWizardStep.UPLOAD_IMAGES);
    }
  }, []);

  useEffect(() => {
    if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.UPLOADING) {
      setLoadingTitles(["Uploading your photos, please wait..."])
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.CREATING_REQUEST) {
      setLoadingTitles(["Adding your photos to the queue, please wait..."])
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.PROCESSING) {
      setLoadingTitles(["Processing your photos, please wait..."])
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.TRAINING) {
      setLoadingTitles(["Training your photos, please check back in 3 to 5 hours..."])
    } else if (aiPhotosProcessingStatus === AIPhotosProcessingStatus.COMPLETE) {
      setLoadingTitles(["Processing complete, please check back in 3 to 5 hours..."])
    }
  }, [aiPhotosProcessingStatus])


  const startProcessingPhotos = async () => {
    setGeneratingAIPhotos(true)
    setAIPhotosWizardComplete(true)
    try {
      await generateAIPhotos()
      clearFilesToUpload()
    } catch (error) {
      throw error
    } finally {
      setGeneratingAIPhotos(false)
    }
  }

  const onPaymentSuccessful = async () => {
    try {
      setOpenUnlockAIPhotosModal(false)
      await startProcessingPhotos()
    } catch (error) {
      console.error('Error creating AI photos request:', error)
      toast.error(error instanceof Error ? error.message : "An unexpected error occurred during payment processing.");
    }
  }

  const onWizardComplete = async (selectedPlan: AIPhotosPlan) => {
    setSelectedPlan(selectedPlan)
    if (filesToUpload.length > 0) {
      setOpenUnlockAIPhotosModal(true)
    } else {
      toast.error('Please upload at least one photo to continue')
    }
  }

  const showResults =
    !generatingAIPhotos &&
    aiPhotosWizardComplete &&
    hasAllRequiredFields

  return (
    <>
      {showLandingPage ? <AIPhotosLanding onNextPress={() => setShowLandingPage(false)} /> : (
        generatingAIPhotos ? (
          <Loading titles={loadingTitles} />
        ) : showResults ? (
          <AIPhotosResults />
        ) : (
          <AIPhotosWizard onBack={() => setShowLandingPage(true)} onWizardComplete={onWizardComplete} />
        )
      )}
      <UnlockAIPhotosModal open={openUnlockAIPhotosModal} setOpen={setOpenUnlockAIPhotosModal} redirectHandler={onPaymentSuccessful} selectedPlan={selectedPlan} />
    </>
  )
}