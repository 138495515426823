import { Modal } from "./Modal";
import { PremiumProductsCarousel } from "../../components/payment/paywalls/PremiumProductsCarousel";
import { ProductType } from "../../models/payment";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  initialKey?: ProductType;
  onClose: () => void;
  handleGetStarted: () => void;
}

export const FeaturesModal = ({ open, setOpen, children, initialKey, onClose, handleGetStarted,
}: Props) => {
  return (
    <>
    <Transition
      show={open}
      enter="transition ease-out duration-300"
      enterFrom="transform translate-y-full opacity-0"
      enterTo="transform translate-y-1/3 opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="transform translate-y-1/3 opacity-100"
      leaveTo="transform translate-y-full opacity-0"
    >
      <div className="top-0 left-0 right-0 bg-black opacity-80 p-4 shadow-lg absolute h-screen"></div>
    </Transition>
    {open && (
      <div className="bg-white h-[38rem] bottom-0 left-0 absolute w-full rounded-t-lg p-4 z-50">
        <div className="lg:max-w-2xl w-full mx-auto">
          <div className="flex justify-center items-center mb-4">
            <div className="w-1/3"></div>
            <div className="w-1/3 flex justify-center">
              <h4 className="font-bold text-xl">Features</h4>
            </div>
            <div className="flex items-end justify-end flex-1">
              <XMarkIcon
                className="w-8 h-8 text-gray-500"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
          {children || (
            <PremiumProductsCarousel
              initialKey={initialKey}
              transparentBackground
            />
          )}
          <button
            className="w-full py-3 bg-[#410B13] text-white rounded-lg shadow-lg mt-4 mb-4 font-medium text-base"
            onClick={handleGetStarted}
          >
            Get Started
          </button>
        </div>
      </div>
    )}
  </>
  // <Modal open={open} setOpen={setOpen}>
  //   <div className="w-full h-full overflow-y-auto bg-gray-200 -mt-6 sm:-mt-8">
  //     <div className="w-full flex justify-end">
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth="2"
  //         stroke="currentColor"
  //         onClick={() => setOpen(false)}
  //         className="w-6 h-6 text-zinc-400 cursor-pointer"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M6 18L18 6M6 6l12 12"
  //         />
  //       </svg>
  //     </div>
  //     <div className="overflow-y-scroll mt-4 mb-6">
  //       {children || <PremiumProductsCarousel initialKey={initialKey} />}
  //     </div>
  //   </div>
  // </Modal>
);
};
