import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Loading } from "../../components/Loading";
import { PremiumProductsCarousel } from '../../components/payment/paywalls/PremiumProductsCarousel';
import { PremiumProducts } from '../../components/payment/paywalls/PremiumProducts';
import { SaleBanner } from '../../components/payment/paywalls/SaleBanner';
import { PlanType } from "../../constants/payments";
import { EventParams, logEvent, useLogEvent } from "../../analytics";
import { Success } from "../../components/Success";
import { PremiumState } from "../../models/payment";
import { useAuthStore } from "../../stores/auth";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ANNUAL_MONTHLY_PRICE, ANNUAL_PRICE, MONTHLY_PRICE, SALE_PERCENTAGE } from "./paymentsConfig";
import { SubscriptionForm } from "../../components/premium/SubscriptionForm";
import { useWizardStore } from "../../stores/wizard";
import { auth } from "../../firebase";
import { checkUserSubscription } from "../../queries";
import { sleep } from "../../utils";
import toast from "react-hot-toast";
import { YourMoveHeader } from "../../components/YourMoveHeader";
import { usePaymentStore } from "../../stores/payment";
import { FamewallReviews } from "../FamewallReviews";
import { usePostHog } from 'posthog-js/react';
import { addToMailingList, updateMailcoachSubscriber } from "../../queries";
import { getProductTypeFromPath } from "../../utils";
import { FeaturesModal } from '../../components/modals/FeaturesModal';
import { ProductType } from '../../models/payment'; // Adjust the import path as needed
import { PremiumFeatures } from '../../components/payment/paywalls/PremiumFeatures';

export const PremiumV2 = ({ product = "chat_assistant", onDismiss, onComplete }: PremiumState) => {
  const { setAuthModalIsOpen, setIsSubscribed, setShowAuthSubscriptionDisclaimer, setAuthModalDismissable } = useAuthStore();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { email } = useWizardStore();
  const emailRef = useRef(email);
  const { isSubscribed } = useAuthStore();
  const { planType, setPlanType } = usePaymentStore();

  const postHog = usePostHog();
  const featureFlag = postHog?.getFeatureFlag('show-sale');
  const salePercentage = featureFlag === 'show' ? SALE_PERCENTAGE : 0;
  const { selectedPlan } = usePaymentStore();

  // Add feature flag override for testing
  // postHog?.featureFlags.override({'bullet_style_pricing': 'control'});
  // const bulletStylePricing = postHog?.getFeatureFlag("bullet_style_pricing");

  useLogEvent('premium_page_view', product);

  useEffect(() => {
    postHog?.capture('premium_page_view', { product })
  }, [])

  useEffect(() => {
    if (paymentSuccess) {
      setTimeout(() => {
        if (!auth?.currentUser) {
          setShowAuthSubscriptionDisclaimer(true);
          setAuthModalDismissable(false);
          setAuthModalIsOpen(true);
        }
        onDismiss();
        onComplete?.();
      }, 2750);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const onMonthlyPress = () => {
    setPlanType(PlanType.Monthly);
    const params: EventParams = {
      amount: `${MONTHLY_PRICE}`,
      payment_type: 'monthly'
    };

    if (featureFlag !== undefined) {
      params['show_sale'] = featureFlag;
    }
    logEvent('purchase_click', product, params, 'payment');
    postHog?.capture('purchase_click', params)

    if (window.location.pathname === '/welcome') {
      postHog?.capture('purchase_click_welcome', params)
    }
  };

  const onAnnualPress = () => {
    setPlanType(PlanType.Yearly);
    const params: EventParams = {
      amount: ANNUAL_PRICE,
      payment_type: 'annual'
    };

    if (featureFlag !== undefined) {
      params['show_sale'] = featureFlag;
    }
    logEvent('purchase_click', product, params, 'payment');
    postHog?.capture('purchase_click', params)

    if (window.location.pathname === '/welcome') {
      postHog?.capture('purchase_click_welcome', params)
    }
  };

  const logPurchaseEvent = () => {
    const params: EventParams = {
      amount: planType === PlanType.Monthly ? `${MONTHLY_PRICE}` : `${ANNUAL_MONTHLY_PRICE}`,
      payment_type: planType === PlanType.Monthly ? 'monthly' : 'annual'
    };
    if (featureFlag !== undefined) {
      params['show_sale'] = featureFlag;
    }
    const currentProduct = getProductTypeFromPath(window.location.pathname);
    logEvent('purchase_activate', currentProduct, params, 'payment');
    postHog?.capture('purchase_activate', params);
    updateMailcoachSubscriber(['premium']);
  // const logPurchaseEvent = () => {
  //   const params: EventParams = {
  //     amount: planType === PlanType.Monthly ? `${MONTHLY_PRICE}` : `${ANNUAL_MONTHLY_PRICE}`,
  //     payment_type: planType === PlanType.Monthly ? 'monthly' : 'annual'
  //   };
  //   if (featureFlag !== undefined) {
  //     params['show_sale'] = featureFlag;
  //   }
  //   logEvent('purchase_activate', product, params, 'payment');
  //   postHog?.capture('purchase_activate', params)

  //   if (window.location.pathname === '/welcome') {
  //     postHog?.capture('purchase_activate_welcome', params)
  //   }
  };


  const onBackPress = () => setPlanType(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProduct, setModalProduct] = useState<ProductType | undefined>(undefined);

  const openFeaturesModal = (productKey: ProductType) => {
    setModalProduct(productKey);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalProduct(undefined);
  };

  return paymentSuccess ? (
    <div className="text-center mt-8">
      <Success title="You have successfully subscribed to Premium." />
    </div>
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="flex justify-center">
        <Helmet>
          <meta
            name="description"
            content="Unlimited access to YourMove AI suite of tools for online dating"
          />
        </Helmet>
        <div className="max-w-xl w-full overflow-y-auto flex flex-col h-full">
          {planType && onBackPress && (
            <div className="flex-shrink-0">
              <YourMoveHeader
                onBack={planType && onBackPress}
                onClose={onDismiss}
                hideBackButton={true}
              />
            </div>
          )}

          <div className="overflow-y-auto flex-grow flex flex-col">
            {isSubscribed ? (
              <div className="text-center mt-8 flex-grow">
                <div className="flex items-center justify-center mb-4">
                  <CheckCircleIcon className="text-white h-16 w-16 stroke" />
                </div>
                <h3>You are already subscribed to YourMove premium.</h3>
              </div>
            ) : planType ? (
              <div className="flex-grow flex flex-col py-2 pb-10 px-2">
                <SubscriptionForm
                  planType={planType}
                  email={emailRef.current}
                  product={product}
                  redirectHandler={async () => {
                    let iterations = 0;
                    let isSubscribed = false;
                    while (isSubscribed === false && iterations < 10) {
                      const isSubscribedResponse = await checkUserSubscription(
                        emailRef.current
                      );
                      const currentProduct = getProductTypeFromPath(window.location.pathname);
                      addToMailingList(emailRef.current, currentProduct);
                      await sleep(1000);
                      iterations++;
                      if (isSubscribedResponse.data.isSubscribed) {
                        isSubscribed = true;
                        logPurchaseEvent();
                        setTimeout(() => {
                          setIsSubscribed(true);
                          setPaymentSuccess(true);
                        }, 500);
                      }
                    }

                    if (!isSubscribed) {
                      toast.error(
                        "You Subscribed but there was an error getting your subscription. Automatically refreshing page"
                      );
                      setTimeout(() => {
                        onDismiss();
                      }, 3000);
                    }
                    // logPurchaseEvent();
                  }}
                />
              </div>
            ) : (
              <div className="flex-grow">
                {/* {bulletStylePricing === "test" ? ( */}
                  <PremiumFeatures
                    openFeaturesModal={(productKey) =>
                      openFeaturesModal(productKey as ProductType)
                    }
                    productType={product as ProductType}
                    onClose={onDismiss}

                  />
                {/* // ) : (
                //   <>
                //     <div className="bg-main py-2">
                //       <p className="text-2xl font-semibold text-black text-center">
                //         Turn swipes into matches <br></br> and matches into
                //         dates
                //       </p>
                //     </div>
                //     <PremiumProductsCarousel initialKey={product} />
                //   </>
                // )} */}
              </div>
            )}
          </div>
        </div>
      </div>
      {!planType && !isSubscribed && (
        <>
          <div className="bg-brand-primary flex justify-center">
            <div className="max-w-xl w-full overflow-hidden flex flex-col">
              <PremiumProducts
                  onMonthlyPress={onMonthlyPress}
                  onAnnualPress={onAnnualPress}
              />
            </div>
          </div>
          <FamewallReviews
            containerStyle="px-2"
            title='Testimonials'
          />
        </>
      )
      }
      {isModalOpen && (
        <FeaturesModal
          initialKey={modalProduct}
          open={isModalOpen}
          setOpen={setIsModalOpen}
          onClose={closeModal}
          handleGetStarted={() => {
            setIsModalOpen(false);
            setTimeout(() => {
              if (selectedPlan === "annual") {
                onAnnualPress();
              } else {
                onMonthlyPress();
              }
            }, 250);
          }}
        >
          <PremiumProductsCarousel
            initialKey={modalProduct}
            transparentBackground
          />
        </FeaturesModal>
      )}
    </>
  );
};
