import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import paywallGraphic from "../../../assets/images/premium/paywall_graphic_new.webp";
import { ProductType } from "../../../models/payment";
// Define the type for the bullets object
type BulletsType = {
  [key in ProductType]: JSX.Element[];
};


interface PremiumFeaturesProps {
  openFeaturesModal: (productKey: string) => void;
  productType: ProductType;
  onClose?: () => void;
  roundedTop?: boolean;
}

const getDynamicBullets = (
  openFeaturesModal: (productKey: string) => void
): BulletsType => ({
  profile_writer: [
    <span key="bullet1">100+ profile prompts to choose from</span>,
    <span key="bullet2">Customize your writing style</span>,
    <span key="bullet3">Trained by matchmakers; powered by AI</span>,
    <span key="bullet4">Our most powerful AI models</span>,
    <span key="bullet5">
      No more ghosting with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => {
            openFeaturesModal("chat_assistant");
          }}
        >
          text assistant
        </button>
      </u>
    </span>,
    <span key="bullet6">
      Unlimited{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_review")}
        >
          profile reviews
        </button>
      </u>
    </span>,
    <span key="bullet7">Works with all dating apps</span>,
  ],
  profile_review: [
    <span key="bullet1">Detailed profile analysis</span>,
    <span key="bullet2">Expert feedback</span>,
    <span key="bullet3">Trained by matchmakers; powered by AI</span>,
    <span key="bullet4">Our most powerful AI models</span>,
    <span key="bullet5">
      Perfect your bio with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_writer")}
        >
          profile writer
        </button>
      </u>
    </span>,
    <span key="bullet6">
      No more ghosting with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("chat_assistant")}
        >
          text assistant
        </button>
      </u>
    </span>,
    <span key="bullet7">Works with all dating apps</span>,

  ],
  chat_assistant: [
    <span key="bullet1">Unlimited messages</span>,
    <span key="bullet2">1.8x more replies with custom openers</span>,
    <span key="bullet3">Our most powerful AI models</span>,
    <span key="bullet4">Trained by matchmakers; powered by AI</span>,
    <span key="bullet5">
      Perfect your bio with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_writer")}
        >
          profile writer
        </button>
      </u>
    </span>,
    <span key="bullet6">
      Unlimited{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_review")}
        >
          profile reviews
        </button>
      </u>
    </span>,
    <span key="bullet7">Works with all dating apps</span>,
  ],
  photo_review: [
    <span key="bullet1">Detailed profile analysis</span>,
    <span key="bullet2">Expert feedback</span>,
    <span key="bullet3">Trained by matchmakers; powered by AI</span>,
    <span key="bullet4">Our most powerful AI models</span>,
    <span key="bullet5">
      No more ghosting with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("chat_assistant")}
        >
          text assistant
        </button>
      </u>
    </span>,
    <span key="bullet6">
      Unlimited{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_review")}
        >
          profile reviews
        </button>
      </u>
    </span>,
    <span key="bullet7">Works with all dating apps</span>,

  ],
  photos: [
    <span key="bullet1">Detailed profile analysis</span>,
    <span key="bullet2">Expert feedback</span>,
    <span key="bullet3">Trained by matchmakers; powered by AI</span>,
    <span key="bullet4">Our most powerful AI models</span>,
    <span key="bullet5">
      No more ghosting with{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("chat_assistant")}
        >
          text assistant
        </button>
      </u>
    </span>,
    <span key="bullet6">
      Unlimited{" "}
      <u>
        <button
          className="text-brand-primary underline"
          onClick={() => openFeaturesModal("profile_review")}
        >
          profile reviews
        </button>
      </u>
    </span>,
    <span key="bullet7">Works with all dating apps</span>,

  ], // Add an empty array or specific bullets for 'photos'
  //   / Add an empty array or specific bullets for 'profile_writer'
  // Add more product types as neede
});

export const PremiumFeatures: React.FC<PremiumFeaturesProps> = ({
  openFeaturesModal,
  productType,
  onClose,
  roundedTop,
}) => {  const bullets = getDynamicBullets(openFeaturesModal)[productType] || [];

  return (
    <>
      <div className="flex justify-center relative">
        {onClose && (
          <div
            className="absolute right-0 bg-black opacity-50 rounded-full cursor-pointer p-1 mr-3 mt-3 z-50"
            onClick={onClose}
          >
            <XMarkIcon className="h-8 w-8 text-white" />
          </div>
        )}
        <div
          className={`relative w-full h-40 bg-[url('/src/assets/images/premium/paywall_graphic_new.webp')] bg-cover -z-10 ${
            roundedTop ? "rounded-t-lg" : ""
          }`}
        >
          <div className="absolute w-full top-0 h-full bg-gradient-to-b from-transparent to-black flex items-end justify-center pb-4 z-30">
            <h3 className="text-3xl font-semibold text-black text-center text-white relative">
              Turn swipes into matches and matches into dates
            </h3>
          </div>
        </div>
      </div>
      <div className="pt-4 px-4 rounded-lg">
        <ul className="list-none">
          {bullets.map((bullet, index) => (
            <li key={index} className="flex items-center mb-5">
              <CheckIcon className="text-brand-primary h-7 w-7 mr-2 font-extrabold" />
              <span className="text-gray-800">{bullet}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
