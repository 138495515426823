import { usePostHog } from 'posthog-js/react'

type Props = {
  title: string;
  popular?: boolean;
  price: number;
  fullPrice?: number;  // New prop
  salePercentage?: number;
  additionalDescription?: string;
  onPress?: VoidFunction;
  isSelected?: boolean;
};

export const SubscriptionPlan = ({
  title,
  popular = false,
  price,
  fullPrice = undefined,
  salePercentage = undefined,
  additionalDescription = undefined,
  onPress = undefined,
  isSelected = false
}: Props) => {
  // const postHog = usePostHog()
  // const showDisclaimer = postHog?.getFeatureFlag('show-moneyback-disclaimer') === 'treatment'

  const discountedPrice = salePercentage ? Math.round(price - price * (salePercentage / 100)) : undefined;
  
  return (
    <div className={`p-2 rounded-lg shadow-md bg-white flex flex-col h-full border-2
      ${isSelected ? 'border-black' : 'border-transparent'}`}>
      <div className="flex flex-col flex-1">
        <div className="flex-1">
          <div className="flex justify-between items-center">
            <p className={`font-medium text-base pb-1 ${isSelected ? 'text-black' : 'text-black'}`}>
              {title}
            </p>
            <p className={`font-bold ${popular ? "text-brand-primary" : "text-[#999999]"} text-[10px]`}>
              {popular ? "Most Popular" : "Flexible Plan"}
            </p>
          </div>
          <div className="mt-0 mb-1">
            {fullPrice && (
              <span className="text-base text-gray-400 line-through pr-1">${fullPrice}/mo</span>
            )}
            <span className={`${discountedPrice ? "text-base text-[#999999] line-through" : "text-base text-[#BA1F33]"} font-semibold`}>
              {`$${price}${discountedPrice ? "" : "/mo"}`}
            </span>
            {discountedPrice ? <span className="text-base font-semibold text-[#BA1F33] ml-1">{`$${discountedPrice}/mo`}</span> : null}
            {additionalDescription && <p className="text-xs text-[#999999]">{additionalDescription}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPlan;
